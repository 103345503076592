import { LumEnvironmentInterface } from '@lum-types';
import { LUM_LOG_LEVEL } from '@lum-utils';
import packageInfo from '../../package.json';

export declare const LUM_BUILD_TIMESTAMP: number;

export const LUM_ENV: LumEnvironmentInterface = {
  production: true,
  env: 'testing',
  version: packageInfo?.version,
  buildTimestamp: LUM_BUILD_TIMESTAMP,
  logLevel: LUM_LOG_LEVEL.All,
  api: {
    baseUrl: 'https://api.testing.connect.lumeso.com',
  },
  dcupl: {
    baseUrl: 'https://api.testing.connect.lumeso.com/dcupl',
    applicationKey: 'connect-lumeso',
  },
  cognito: {
    userPoolClientId: '7irvgkln532bc3rt66mr3qr639',
    userPoolId: 'eu-central-1_pnn8UtZtE',
    loginWith: {
      oauth: {
        domain:
          'lumeso-authorizers-testing.auth.eu-central-1.amazoncognito.com',
        scopes: ['openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: ['https://testing.connect.lumeso.com/'],
        redirectSignOut: ['https://testing.connect.lumeso.com/login'],
        responseType: 'code',
      },
    },
  },
};
